<template>
  <div>
    <!-- 导航 -->
    <div class="nav">
      <div class="logo">
        <div>
          <img src="../assets/logo.png" class="img40" />
        </div>
        <span>高校论文预审系统</span>
      </div>
      <div class="tabs">
        <div :class="index == 0 ? 'active' : ''" @click="toPage(1)">首页</div>
        <div
          :class="index == 1 || index == 2 ? 'active' : ''"
          @click="toPage(2)"
        >
          产品介绍
        </div>
        <div
          :class="index == 3 || index == 4 ? 'active' : ''"
          @click="toPage(4)"
        >
          特色功能
        </div>
        <div :class="index == 5 ? 'active' : ''" @click="toPage(6)">
          联系我们
        </div>
      </div>
    </div>
    <full-page :options="options" ref="fullpage" @onLeave="onLeave">
      <!-- 第一页 -->
      <div class="section page1-wrap">
        <!-- 主要内容 -->
        <div class="content1">
          <!-- 左边 -->
          <div class="summary">
            <!-- 最上面的标题 -->
            <div class="title1">
              <img src="../assets/imgs/Saly-43.webp" class="img195" />
              <div>全国多所院校</div>
              <div>都在使用的论文预审系统</div>
            </div>
            <!-- 中间TIP -->
            <div class="tip1">
              <span>独有算法,所有流程一个系统就解决了!</span>
            </div>
            <!-- 下面的宫格 -->
            <div class="submenu">
              <div>
                <div>
                  <img src="../assets/imgs/subm1.png" class="img20" />
                  <span>高校平台</span>
                </div>
                <div>
                  <img src="../assets/imgs/subm2.png" class="img20" /><span
                    >统一平台</span
                  >
                </div>
                <div>
                  <img src="../assets/imgs/subm3.png" class="img20" /><span
                    >独有算法</span
                  >
                </div>
              </div>
              <div>
                <div>
                  <img src="../assets/imgs/subm4.png" class="img20" /><span
                    >方便快捷</span
                  >
                </div>
                <div>
                  <img src="../assets/imgs/subm5.png" class="img20" /><span
                    >高效环保</span
                  >
                </div>
                <div>
                  <img src="../assets/imgs/subm6.png" class="img20" /><span
                    >通用账号</span
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- 右边 -->
          <div style="position: relative">
            <img src="../assets/imgs/Saly.png" class="img700" />
            <img src="../assets/imgs/page1/mail.png" class="p1-mail" />
            <img src="../assets/imgs/page1/quite.png" class="p1-quite" />
            <img src="../assets/imgs/page1/object.png" class="p1-object" />
            <img src="../assets/imgs/page1/chart.png" class="p1-chart" />
          </div>
        </div>
      </div>

      <!-- 第二页 -->
      <div class="section page2-wrap">
        <div class="inner2">
          <div class="title-all tpo">
            <span>快速 方便 安全</span>
            <div class="line"></div>
            <img src="../assets/imgs/page2/three.png" class="p2-three" />
            <img src="../assets/imgs/page2/circlelv.png" class="p2-circlelv" />
            <img
              src="../assets/imgs/page2/circlehui.png"
              class="p2-circlehui"
            />
          </div>

          <div class="videoWrapper">
            <img
              src="../assets/imgs/page2/bigciecle.png"
              class="p2-bigciecle"
            />
            <video
              autoplay
              controls
              src="../assets/video/video.mp4"
              class="video"
            ></video>
            <img class="img" src="../assets/imgs/Saly-39.png" />
          </div>
        </div>
      </div>

      <!-- 第三页 -->
      <div class="section page3-wrap">
        <div class="inner3">
          <div class="title-all">
            <span>我们的目标</span>
            <div class="line"></div>
          </div>
          <div class="content3">
            <div class="item">
              <div class="item-div">
                <img src="../assets/imgs/Saly-44.png" class="img-31" />
              </div>
              <span class="title3">信息化、无纸化</span>
              <span class="span3"
                >产品通过信息化的手段实现线上全流程论文审批，解决目前高校传纸质论文提交及审核的现状，实现论文审批的信息化、无纸化。
</span
              >
            </div>
            <div class="item">
              <div class="item-div">
                <img src="../assets/imgs/Saly-45.png" class="img-32" />
              </div>
              <span class="title3">信息安全、稳定可靠</span>
              <span class="span3"
                >建立可靠的安全体系，不间断、容错、传输可靠、可管理，以防止对信息系统的非法入侵和攻击，保证系统能长期稳定有效的运行。</span
              >
            </div>
            <div class="item">
              <div class="item-div">
                <img src="../assets/imgs/Saly-27.png" class="img-33" />
              </div>
              <span class="title3">流程闭环、操作简洁</span>
              <span class="span3"
                >产品实现从提交论文到论文预审通过的整个流程。产品页面设计精美，操作简洁，并且提供移动端支持，是学生和老师的操作更加便捷。</span
              >
            </div>
            <div class="item">
              <div class="item-div">
                <img src="../assets/imgs/Saly-25.png" class="img-34" />
              </div>
              <span class="title3">解决痛点、提升效率</span>
              <span class="span3"
                >切实解决学校在论文预审各个环节的痛点问题，打破传统论文资源的收集、分类和归档、存放的限制，提升整体管理水平，给师生的学习工作带来便利。</span
              >
            </div>
          </div>
        </div>
      </div>

      <!-- 第四页 -->
      <div class="section page4-wrap">
        <div class="inner4">
          <div class="wrapper">
            <div class="float-41">
              <img src="../assets/imgs/page4/float1.png" class="flf" />
              <img src="../assets/imgs/page4/float-bg.png" class="bgf" />
            </div>
            <img src="../assets/imgs/page4/float-4.png" class="p4-float" />
            <div class="float-42">
              <img src="../assets/imgs/page4/float2.png" />
            </div>
            <div class="float-43">
              <img src="../assets/imgs/page4/float3.png" />
            </div>
            <div class="bg">
              <img src="../assets/imgs/bg1.png" alt="" />
            </div>
            <div class="box">
              <div class="inner">
                &emsp;<span class="yellow">高校管理信息化</span>
                是高校信息化的重要内容之一，常见的信息系统，诸如教务管理系统、科研管理系统、人事管理系统、财务管理系统和档案管理系统等都以校务核心业务、部门纵向业务为主，在一定层度上满足了高校的发展需求，有效提升了高校的管理水平，对深化高校管理体制改革、增强高校综合实力具有重要作用；<br /><br />
                &emsp;但高校信息化建设仍存在部分缺口，如毕业生论文预审这一块，目前大多数高校仍采用线下打印提交的方式。<br />据《<b>央广新闻</b>》报道，<u>每年一到毕业季，高校毕业生们都在为最后的答辩做准备</u>，同时各大高校周边的打印店生意也火爆起来了。据统计，在论文初版到定稿的过程中平均一名毕业生要打印5-7份论文，全国高校毕业生仅打印论文就会用去<b>四千多吨纸</b>，相当于消耗掉
                <b class="yellow">7万多棵20年树龄的大树。</b><br /><br />
                &emsp;高校论文预审管理系统主要用于解决目前高校传统纸质论文提交及审核的现状，用信息化的手段实现线上全流程论文审批，实现“<b>毕业论文信息化、无纸化</b>”，这不但能提高学校论文审核的管理水平，给师生的学习工作带来便利，还能节省大量的社会资源，减少生态破坏，体现高校的社会责任感，符合高校“领风气之先”的身份。
              </div>
            </div>
          </div>
          <div class="right">
            <div class="title-all">
              <span>高校论文产品特色</span>
              <div class="line"></div>
            </div>
            <div class="tip">
              云计算、大数据、“互联网+”等新理念、新技术也在高校信息化建设中得到了广泛应用，使高校信息化建设取得了显著成效。
            </div>
            <div style="float: right; transform: rotateY(180deg)">
              <img src="../assets/imgs/bg2.png" class="img283" />
            </div>
          </div>
        </div>
      </div>

      <!-- 第五页 -->
      <div class="section page5-wrap">
        <div class="inner5">
          <div>
            <div class="title-all">
              <span>丰富的应用场景</span>
              <div class="line"></div>
            </div>
            <div class="nav5">
              <div class="item" @click="changeMoveIndex(0)">
                <img v-if="moveIndex != 0"
                  src="../assets/imgs/page5/i1.svg"
                  :class="moveIndex == 0 ? 'moveround-active' : ''"
                />
                <img v-if="moveIndex == 0"
                  src="../assets/imgs/page5/i1-s.svg"
                  :class="moveIndex == 0 ? 'moveround-active' : ''"
                />
                <div
                  class="text"
                  :class="moveIndex == 0 ? 'movetext-active' : ''"
                >
                  实现多端同步操作
                </div>
              </div>
              <div class="item" @click="changeMoveIndex(1)">
                <img v-if="moveIndex != 1"
                  :class="moveIndex == 1 ? 'moveround-active' : ''"
                  src="../assets/imgs/page5/i2.svg"
                />
                <img v-if="moveIndex == 1"
                  :class="moveIndex == 1 ? 'moveround-active' : ''"
                  src="../assets/imgs/page5/i2-s.svg"
                />
                <div
                  class="text"
                  :class="moveIndex == 1 ? 'movetext-active' : ''"
                >
                  实时掌握论文预审进度
                </div>
              </div>
              <div class="item" @click="changeMoveIndex(2)">
                <img v-if="moveIndex != 2"
                  :class="moveIndex == 2 ? 'moveround-active' : ''"
                   src="../assets/imgs/page5/i3.svg"
                />
                <img v-if="moveIndex == 2"
                  :class="moveIndex == 2 ? 'moveround-active' : ''"
                   src="../assets/imgs/page5/i3-s.svg"
                />
                <div
                  class="text"
                  :class="moveIndex == 2 ? 'movetext-active' : ''"
                >
                  预审论文批注及时查看
                </div>
              </div>
              <div class="item" @click="changeMoveIndex(3)">
                <img v-if="moveIndex != 3"
                  :class="moveIndex == 3 ? 'moveround-active' : ''"
                  src="../assets/imgs/page5/i4.svg"
                />
                <img v-if="moveIndex == 3"
                  :class="moveIndex == 3 ? 'moveround-active' : ''"
                  src="../assets/imgs/page5/i4-s.svg"
                />
                <div
                  class="text"
                  :class="moveIndex == 3 ? 'movetext-active' : ''"
                >
                  高校论文库建设
                </div>
              </div>
            </div>
          </div>
          <div class="box-img">
            <div :class="'move' + moveIndex">
              <img src="../assets/imgs/movebg1.png" class="img-5" />
              <img src="../assets/imgs/movebg2.png" class="img-5" />
              <img src="../assets/imgs/movebg3.png" class="img-5" />
              <img src="../assets/imgs/movebg4.png" class="img-5" />
            </div>
          </div>
        </div>
      </div>`

      <!-- 第六页 -->
      <div class="section page6-wrap">
        <div class="inner6">
          <div class="title-all">
            <span>联系我们</span>
            <div class="line"></div>
          </div>
          <!-- 地图 -->
          <div class="addressWrap">
            <img src="../assets/imgs/page6/float-6.png" class="p6-float" />
            <div class="map">
              <img src="../assets/imgs/map.png" class="mapImg" />
            </div>
            <div class="msg">
              <!-- 地址 -->
              <div class="top">
                <div>
                  <div class="address iconB"></div>
                  <div class="title6">地址</div>
                </div>
                <div class="box6 address-msg">
                  武汉市东湖新技术开发区光谷大道41号<br />现代光谷国际世贸中心<span
                    class="text-yellow"
                    >F</span
                  >栋<span class="text-yellow">1405-1406</span>室
                </div>
              </div>
              <!-- 电话和邮箱 -->
              <div class="middle">
                <div class="m-left">
                  <div>
                    <div class="phone iconB"></div>
                    <div class="title6">电话</div>
                  </div>
                  <div class="box6 phone-msg">400 669 8659</div>
                </div>
                <div class="m-right">
                  <div>
                    <div class="iconB mail"></div>
                    <div class="title6">邮箱</div>
                  </div>
                  <div class="box6 mail-msg">daliu@whdlxx.cn</div>
                </div>
              </div>
              <!-- 公众号和官方网站 -->
              <div class="bottom">
                <div class="m-left">
                  <div>
                    <div class="like iconB"></div>
                    <div class="title6">公众号</div>
                  </div>
                  <div class="box6 like-msg">
                    <img src="../assets/imgs/wxqr.png" class="img185" />
                  </div>
                </div>
                <div class="m-right">
                  <div>
                    <div class="iconB web"></div>
                    <div class="title6">官方网站</div>
                  </div>
                  <div class="box6 web-msg">
                    <img src="../assets/imgs/qr.png" class="img185" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 底部foot -->
          <div class="foot">
            <!-- LOGO部分 -->
            <div class="f-top">
              <div class="logo">
                <div>
                  <img src="../assets/logo.png" class="img20" />
                </div>
                <span>高校论文预审系统</span>
              </div>
              <div class="about">
                <span>关于大刘信息</span>
                <span>知识产权</span>
                <span>隐私保护</span>
              </div>
            </div>
            <!-- 我们具备 -->
            <div class="f-middle">
              我们具备专业强大的运维团队，提供工作日5*24小时全时响应、非工作日专业人员值班维护，
              全年365天无休的运维响应，秉承“客户至上”的服务宗旨，严格执行电话、邮件全年响应、快速到达现场、
              两小时内提供解决方案、两天内解决问题、客户满意度追踪的运维体系，保证客户的问题在
              第一现场、第一时间、第一质量得到解决。
            </div>
            <!-- 底部logo -->
            <div class="f-bottom">
              <div class="f-img">
                <img src="../assets/imgs/logo2.png" />
              </div>
              <div class="fb-text">
                Copyright © 2010-2030 版权所有 ALL RIGHTS
                RESERVED. 鄂ICP备18008520号 武汉市大刘信息技术有限公司
              </div>
            </div>
          </div>
        </div>
      </div>
    </full-page>
  </div>
</template>
<script>
export default {
  data() {
    return {
      moveIndex: 0,
      start: 0,
      options: {
        licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
        afterLoad: this.afterLoad,
        scrollOverflow: true,
        scrollingSpeed: 700,
        scrollBar: false,
        menu: "#menu",
        sectionsColor: [],
        onLeave: this.onLeave,
        afterLoad: this.afterLoad,
      },
      index: 0,
    };
  },
  mounted() {},
  methods: {
    toPage(v) {
      this.$refs.fullpage.api.moveTo(v);
    },

    onLeave(index, nextIndex, direction) {
      this.index = nextIndex.index;
      // if (this.index == 4) {
      //   this.$refs.fullpage.api.setAllowScrolling(false, "all");
      // }
    },
    changeMoveIndex(index) {
      this.moveIndex = index;
    },
    handleWheel(e) {
      let current = Date.now();
      if (current - this.start > 1000) {
        // if (e.deltaY < 0 && this.moveIndex == 0) {
        //   this.$refs.fullpage.api.setAllowScrolling(true, "all");
        // }
        // if (e.deltaY > 0 && this.moveIndex == 3) {
        //   this.$refs.fullpage.api.setAllowScrolling(true, "all");
        // }
        // 滚轮向上
        if (e.deltaY < 0 && this.moveIndex > 0 && this.index == 4) {
          this.moveIndex--;
        }
        // 滚轮向下
        if (e.deltaY > 0 && this.moveIndex < 3 && this.index == 4) {
          this.moveIndex++;
        }
        this.start = current;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.img20 {
  width: 20px;
  height: 20px;
}
.img40 {
  width: 40px;
  height: 40px;
}
.img185 {
  width: 185px;
  height: 185px;
}
.img195 {
  width: 195px;
  height: 195px;
}
.img283 {
  width: 283px;
  height: 283px;
}
.img700 {
  width: 700px;
  height: 700px;
  animation: bounce-down 5s linear infinite;
}

@keyframes bounce-down {
  25% {
    transform: translateY(-6px);
  }
  50%,
  100% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(6px);
  }
}

.movetext-active {
  font-weight: 700;
  color: #03bb7a !important;
}
.moveround-active {
  background-color: #03bb7a !important;
}

.move0 {
  position: absolute;
  left: 0;
  top: 0;
  // transform: translateY(0px);
  transition: all 0.9s ease 0s;
}
.move1 {
  position: absolute;
  left: 0;
  top: -850px;
  // transform: translateY(-450px);
  transition: all 0.9s ease 0s;
}
.move2 {
  position: absolute;
  left: 0;
  top: -1700px;
  // transform: translateY(-1800px);
  transition: all 0.9s ease 0s;
}
.move3 {
  position: absolute;
  left: 0;
  top: -2550px;
  // transform: translateY(-2700px);
  transition: all 0.9s ease 0s;
}

.yellow {
  background-color: #ffc300;
}
.title-all {
  font-size: 42px;
  font-weight: 700;
  position: relative;
  .line {
    background-color: #fcee6a;
    height: 12px;
    width: 110%;
    position: absolute;
    bottom: 0;
    z-index: -1;
    left: -5%;
  }
  span {
    display: block;
    text-align: center;
  }
}
.nav {
  box-sizing: border-box;
  padding: 0 350px;
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background-color: #ebf5ff;
  z-index: 999;
  .logo {
    display: flex;
    > div {
      width: 60px;
      height: 60px;
      background-color: #fff;
      text-align: center;
      border-radius: 50%;
      margin-right: 10px;
      img {
        margin-top: 10px;
      }
    }
    span {
      display: block;
      font-size: 24px;
      font-weight: 700;
      line-height: 60px;
    }
  }
  .tabs {
    display: flex;
    padding: 10px 0;
    font-size: 18px;
    > div {
      height: 40px;
      margin-left: 50px;
      line-height: 40px;
      padding: 0 30px;
      font-weight: 700;
      cursor: pointer;
    }
    .active {
      color: #fff;
      background-color: #03bb7a;
      border-radius: 20px;
    }
  }
}

.page1-wrap {
  background-image: url("../assets/imgs/page1/daliu.png"),
    url("../assets/imgs/page1/lanq.png"), url("../assets/imgs/page1/ziq.png"),
    url("../assets/imgs/page1/lvq.png");
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-size: 1286px 545px, 374px 374px, 492px 492px, 316px 316px;
  background-position: 50% 50%, 75% 35%, 110% 80%, 10% -10%;
  box-sizing: border-box;
  // /deep/ .fp-scroller {
  //   height: 100%;
  // }
  .content1 {
    height: calc(100vh - 100px);
    padding: 100px 350px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .summary {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title1 {
        font-size: 48px;
        font-weight: 700;
        margin-bottom: 35px;
        position: relative;
        > img {
          position: absolute;
          right: 40px;
          top: -100px;
          transform: rotate(55deg);
        }
      }
      .tip1 {
        height: 40px;
        margin-bottom: 30px;

        span {
          background-color: #e3fff0;
          padding: 9px 40px 9px 10px;
          font-size: 20px;
          color: #15559e;
        }
      }
      .submenu {
        display: flex;
        flex-direction: column;
        background-color: rgba(255, 258, 258, 0.6);
        padding: 50px 35px;
        border-radius: 20px;
        box-shadow: 0px 5px 50px 0px rgba(0, 0, 0, 0.1);
        > div:first-child {
          margin-bottom: 60px;
        }
        > div {
          color: #808080;
          font-size: 16px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          img {
            vertical-align: middle;
            margin-right: 6px;
          }
          span {
            vertical-align: middle;
          }
        }
      }
    }
    .p1-mail {
      width: 81px;
      height: 81px;
      position: absolute;
      left: 80px;
      top: 80px;
      animation: bounce-down 3.1s linear infinite;
    }
    .p1-quite {
      width: 85px;
      height: 63px;
      position: absolute;
      left: 50px;
      top: 260px;
      animation: bounce-down 3.8s linear infinite;
    }
    .p1-object {
      width: 52px;
      height: 52px;
      position: absolute;
      left: 500px;
      top: 180px;
      animation: bounce-down 3s linear infinite;
    }
    .p1-chart {
      width: 71px;
      height: 93px;
      position: absolute;
      left: 550px;
      top: 500px;
      animation: bounce-down 3.9s linear infinite;
    }
  }
}

.page2-wrap {
  background-image: url("../assets/imgs/page1/ziq.png"),
    url("../assets/imgs/page1/lvq.png");
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-size: 492px 492px, 492px 492px;
  background-position: 120% 80%, 10% 90%;
  .inner2 {
    box-sizing: border-box;
    padding: 100px 350px 0;
    .tpo {
      position: relative;
      .p2-three {
        width: 64px;
        height: 64px;
        position: absolute;
        top: 1%;
        left: -200%;
        animation: bounce-down 3.4s linear infinite;
      }
      .p2-circlelv {
        width: 17px;
        height: 17px;
        position: absolute;
        top: 60%;
        left: 140%;
        animation: bounce-down 3s linear infinite;
      }
      .p2-circlehui {
        width: 17px;
        height: 17px;
        position: absolute;
        top: 1%;
        left: -80%;
        animation: bounce-down 3.8s linear infinite;
      }
    }
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .videoWrapper {
      position: relative;
      margin-top: 110px;
      border: 10px solid #e5e5e5;
      background-color: #e5e5e5;
      border-radius: 20px;
      .video {
        width: 900px;
        height: 500px;
        border-radius: 20px;
      }
      .p2-bigciecle {
        width: 470px;
        height: 470px;
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -99;
      }
      .img {
        width: 324px;
        height: 324px;
        position: absolute;
        right: 0;
        bottom: 0;
        transform: translate(280px, 60px);
      }
    }
  }
}

.page3-wrap {
  background-image: url("../assets/imgs/page1/lvq.png");
  background-repeat: no-repeat;
  background-size: 540px 540px;
  background-position: 50% 90%;
  .inner3 {
    box-sizing: border-box;
    padding: 100px 350px 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .content3 {
      width: 100%;
      box-sizing: border-box;
      margin-top: 150px;
      display: flex;
      justify-content: space-between;
      .item {
        display: flex;
        width: 20%;
        flex-direction: column;
        align-items: center;
        margin-right: 30px;
        .img-31 {
          width: 120px;
          height: 120px;
          vertical-align: middle;
        }
        .img-32 {
          width: 150px;
          height: 150px;
          vertical-align: middle;
        }
        .img-33 {
          width: 150px;
          height: 107px;
          vertical-align: middle;
        }
        .img-34 {
          width: 150px;
          height: 80px;
          vertical-align: middle;
        }
        .item-div {
          height: 150px;
          text-align: center;
          line-height: 150px;
        }
        .title3 {
          color: #03bb7a;
          margin-bottom: 22px;
          font-size: 16px;
        }
        .span3 {
          font-size: 13px;
          color: #808080;
          line-height: 1.7;
          letter-spacing: 1px;
        }
      }
      .item:last-child {
        margin: 0;
      }
    }
  }
}

.page4-wrap {
  background-image: url("../assets/imgs/page1/lvq.png");
  background-repeat: no-repeat;
  background-size: 540px 540px;
  background-position: 50% 90%;
  .inner4 {
    box-sizing: border-box;
    padding: 100px 350px 0 400px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .wrapper {
       transform: scale(0.9);
      position: relative;
      .p4-float {
        position: absolute;
        width: 154px;
        height: 154px;
        left: -265px;
        top: -150px;
        animation: bounce-down 3.1s linear infinite;
        z-index: -9;
      }
      .float-41 {
        position: relative;
        width: 71px;
        height: 71px;
        border-radius: 50%;
        position: absolute;
        left: -70px;
        top: -50px;
        line-height: 71px;
        text-align: center;
        .flf {
          width: 32px;
          height: 32px;
          vertical-align: middle;
        }
        .bgf {
          position: absolute;
          width: 71px;
          height: 71px;
          left: 0;
          top: 0;
          border-radius: 50%;
          opacity: 0.5;
        }
      }
      .float-42 {
        width: 65px;
        height: 65px;
        background-color: #fff;
        border-radius: 20px;
        position: absolute;
        left: 40px;
        bottom: -20px;
        text-align: center;
        line-height: 65px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        img {
          width: 32px;
          height: 32px;
          vertical-align: middle;
        }
      }

      .float-43 {
        width: 65px;
        height: 65px;
        background-color: #fff;
        border-radius: 20px;
        position: absolute;
        top: 150px;
        right: -20px;
        text-align: center;
        line-height: 65px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        img {
          width: 32px;
          height: 32px;
          vertical-align: middle;
        }
      }

      .bg {
        position: absolute;
        left: -111px;
        top: -97px;
        z-index: -1;
        img {
          width: 583px;
          height: 583px;
        }
      }
      .box {
        box-sizing: border-box;
        width: 591px;
        border-radius: 20px;
        background: linear-gradient(
          0deg,
          rgba(3, 187, 122, 0.1) 0%,
          rgba(209, 209, 209, 0.1) 54.86%,
          rgba(255, 255, 255, 0.1) 100%
        );
        padding: 25px;
        .inner {
          box-sizing: border-box;
          height: 100%;
          background-color: #fff;
          padding: 29px 48px 30px 54px;
          letter-spacing: 1.5px;
          line-height: 1.2;
          font-size: 16px;
        }
      }
    }
    .right {
       transform: scale(0.9);
      .tip {
        margin-top: 30px;
        width: 450px;
        color: #808080;
        line-height: 1.8;
        letter-spacing: 2px;
        margin-bottom: 83px;
        font-size: 16px;
      }
    }
  }
}

.page5-wrap {
  background-image: url("../assets/imgs/page1/lvq.png");
  background-repeat: no-repeat;
  background-size: 540px 540px;
  background-position: -50% 50%;
  .inner5 {
    height: 100%;
    box-sizing: border-box;
    padding: 100px 350px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .box-img {
      width: 789px;
      height: 850px;
      position: relative;
      overflow: hidden;
    }
    .img-5 {
      width: 789px;
      height: 850px;
      vertical-align: middle;
    }
    .nav5 {
      .item {
        cursor: pointer;
        margin-top: 40px;
        display: flex;
        background-color: #fff;
        margin-bottom: 20px;
        padding: 18px 29px;
        border-radius: 20px;
        box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
        width: 394px;
        img {
          width: 16px;
          height: 16px;
          padding: 16px;
          background-color: #e5e5e5;
          border-radius: 50%;
          margin-right: 26px;
        }
        .text {
          line-height: 48px;
          font-size: 18px;
          color: #a6a6a6;
        }
      }
    }
  }
}

.page6-wrap {
  background-image: url("../assets/imgs/page1/lvq.png");
  background-repeat: no-repeat;
  background-size: 540px 540px;
  background-position: 50% 90%;
  .inner6 {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .addressWrap {
      background-color: #fff;
      padding: 29px;
      transform: scale(0.8);
      position: relative;
      border-radius: 20px;
      .p6-float {
        position: absolute;
        width: 320px;
        height: 320px;
        left: -200px;
        top: 400px;
        z-index: -99;
        animation: bounce-down 3.1s linear infinite;
      }
      .map {
        float: left;
        .mapImg {
          width: 477px;
          height: 544px;
        }
      }
      .msg {
        float: right;
        margin-left: 25px;
        font-size: 16px;
        .top {
          .address {
            background-image: url("../assets/imgs/address.svg");
          }
          .address-msg {
            padding: 14px 82px;
            width: 445px;
          }
        }
        .middle {
          overflow: hidden;
          margin-top: 39px;
          .m-left {
            float: left;
            margin-right: 46px;
            .phone {
              background-image: url("../assets/imgs/phone.svg");
            }
            .phone-msg {
              width: 182px;
              text-align: center;
              padding: 20px;
              font-size: 20px;
            }
          }
          .m-right {
            float: left;
            .mail {
              background-image: url("../assets/imgs/mail.svg");
            }
            .mail-msg {
              padding: 20px;
              font-size: 20px;
              text-align: center;
              width: 217px;
            }
          }
        }
        .bottom {
          margin-top: 19px;
          .m-left {
            float: left;
            margin-right: 16px;
            .like {
              background-image: url("../assets/imgs/like.svg");
            }
            .like-msg {
              padding: 15px;
              img {
                box-sizing: border-box;
                background-color: #fff;
              }
            }
          }
          .m-right {
            float: left;
            .web {
              background-image: url("../assets/imgs/web.svg");
            }
            .web-msg {
              padding: 15px;
              img {
                box-sizing: border-box;
                padding: 10px;
                background-color: #fff;
              }
            }
          }
        }
      }
    }
    .foot {
      box-sizing: border-box;
      width: 100%;
      background-color: #383838;
      padding: 30px 200px 0;
      font-size: 16px;
      .f-top {
        overflow: hidden;
        .logo {
          float: left;
          overflow: hidden;
          div {
            margin-right: 8px;
            float: left;
            width: 29px;
            height: 29px;
            border-radius: 50%;
            background-color: #fff;
            text-align: center;
            img {
              margin-top: 4px;
            }
          }
          span {
            line-height: 29px;
            color: #fff;
          }
        }
        .about {
          float: right;
          line-height: 29px;
          font-size: 12px;
          color: #a6a6a6;
          span:first-child {
            color: #03bb7a;
            text-decoration: underline;
          }
          span:not(:first-child) {
            margin-left: 23px;
            text-decoration: line-through;
          }
        }
      }

      .f-middle {
        padding: 20px 40px;
        line-height: 2;
        font-size: 12px;
        color: #e5e5e5;
      }
      .f-bottom {
        border-top: 1px solid rgba(204, 204, 204, 0.1);
        padding: 20px 0;
        .f-img {
          float: left;
          img {
            width: 235px;
            height: 40px;
          }
        }
        .fb-text {
          float: right;
          line-height: 40px;
          color: #9c9c9c;
          font-size: 12px;
        }
      }
    }
  }
}
.text-yellow {
  color: #ff8d1a;
}
.box6 {
  margin-top: 15px;
  box-sizing: border-box;
  background-color: #fafafa;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  color: #808080;
}
.title6 {
  line-height: 28px;
}

.iconB {
  float: left;
  width: 28px;
  height: 28px;
  margin-right: 8px;
  background-color: #383838;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: 6px 6px;
  background-size: 16px 16px;
}
@media screen and (max-width: 768px){
.nav{
    padding: 0vw  6vw;
    height: 14vw;
  }
  .nav .logo span{
    font-size: 4vw;
  }
  .nav .logo > div{
    width: 10vw;
    height: 10vw;
    margin-right: 2vw;
  }
  .nav .logo .img40{
    width: 100%;
    height: 100%;
  }
  .nav .logo{
    justify-content: center;
    align-items: center;
  }
  .nav .tabs{
    display: none;
  }
  .page1-wrap {
    background-position: 50% 43%, 75% 35%, 110% 80%, 10% -10%;
  }
  .page1-wrap .content1 {
    padding: 5vw 6vw;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .page1-wrap .content1 .img700 {
    width: 68vw;
    height: 68vw;
  }
  .page1-wrap .content1 .summary{
    width: 100%;
  }

  .page1-wrap .content1 .summary .title1{
    font-size: 6vw;
    margin-bottom: 6vw;
  }
  
  .page1-wrap .content1 .summary .tip1{
    height: 6vw;
    margin-bottom: 3vw;
  }
  .page1-wrap .content1 .summary .tip1 span{
    font-size: 2vw;
  }
  .page1-wrap .content1 .summary .submenu{
    padding: 6vw 4vw;
  }
  .page1-wrap .content1 .summary .submenu .img20{
    width: 5vw;
    height: 5vw;
    margin-right: 1vw;
  }
  .page1-wrap .content1 .summary .submenu > div{
    font-size: 2vw;
  }
  .page2-wrap .inner2{
    padding: 5vw 6vw;
  }
  .title-all{
    font-size: 6vw;
  }

  .page2-wrap .inner2 .videoWrapper{
      margin-top: 14vw;
      width: 90%;
  }
  .page2-wrap .inner2 .videoWrapper .video{
    width: 100%;
    height: auto;
  }
  .page2-wrap .inner2 .videoWrapper .img{
    display: none;
  }
  .page3-wrap .inner3{
    padding: 5vw 6vw;
  }
  .page3-wrap .inner3 .content3{
    flex-direction: column;
    margin-top: 4vw;
  }
  .page3-wrap .inner3 .content3 .item{
    width: 100%;
  }
  .page3-wrap .inner3 .content3 .item .item-div{
    height: 10vw;
  }
  .page3-wrap .inner3 .content3 .item .img-31{
    width: 10vw;
    height: 10vw;
  }
  .page3-wrap .inner3 .content3 .item .title3{
    margin-bottom: 2vw;
    font-size: 4vw;
  }
  .page3-wrap .inner3 .content3 .item .span3{
    font-size: 3vw;
  }
.page4-wrap .inner4 {
    padding: 0vw 6vw;
    flex-direction: column;
    padding-top: 20vw;
    justify-content: center;
  }
  
  .page4-wrap .title-all .line {
      height: 0.625vw;
      width: 100%;
      left: 0vw;
  }
  
  .page4-wrap .inner4 .wrapper .box{
    width: 100%;
  }
  .page4-wrap .inner4 .wrapper .box .inner{
    font-size: 2vw;
  }
  .page4-wrap .inner4 .right .tip{
    margin-top: 6vw;
    width: 100%;
    font-size:2vw
  }
  .page5-wrap .inner5{
    padding: 5vw 6vw 0;
    position: relative;
    height: 100vh;
  }
  .page5-wrap .inner5 .title-all .line{
    width: 80%;
    left: 10%;
  }
  .page5-wrap .inner5 .nav5 .item .text{
    line-height: 1;
    font-size:2vw;
  }
  .page5-wrap .inner5>div:first-child{
    position: absolute;
    z-index: 9;
    margin-top: -110vw;
  }
  .page5-wrap .inner5 .nav5 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 2vw;
  }
  .page5-wrap .inner5 .nav5 .item{
    width: 38vw;
    padding: 2vw;
  }
  .page5-wrap .inner5 .box-img{
    width: 100vw;
    height: 100vw;
    margin-left: -6vw;
  }
  .page5-wrap .inner5 .img-5{
    width: 100vw;
    height: 100vw;
  }
  .page5-wrap .inner5 .move0{
    position: relative;
  }
  .page5-wrap .inner5 .move1 {
    top: -100vw;
    position: relative;
  }
  .page5-wrap .inner5 .move2 {
    top: -200vw;
    position: relative;
  }
  .page5-wrap .inner5 .move3{
    top: -300vw;
    position: relative;
  }
  .page6-wrap .title6{
    line-height: 1.4;
  }
  .page6-wrap .inner6{
    padding-top: 15vw;
    justify-content: center;
  }
  .page6-wrap .inner6 .addressWrap .map{
    float: inherit;
  }
  .page6-wrap .inner6 .addressWrap .msg{
    font-size: 2vw;
  }
  .page6-wrap .inner6 .addressWrap .map .mapImg{
    object-fit: cover;
    height: 50vw;
    width: 100%;
  }
  .page6-wrap .inner6 .addressWrap .msg .top .address-msg{
    width: 100%;
  }
  .page6-wrap .inner6 .addressWrap .msg .middle .m-left .phone-msg{
      width: 33vw;
      text-align: center;
      font-size: 2vw;
  }
  .page6-wrap .inner6 .addressWrap .msg .middle .m-right .mail-msg{
    width: 60vw;
    font-size: 2vw;
  }
  .page6-wrap .inner6 .addressWrap .msg .bottom {
    margin-top:2vw;
    display: flex;
    justify-content: space-between;
  }
  .page6-wrap .inner6 .img185{
      width: 40vw;
      height: 40vw;
  }
  .page6-wrap .inner6 .foot{
      padding: 3vw 6vw;
      font-size: 2vw;
  }
  .page6-wrap .inner6 .foot .f-top .logo div{
    display: none;
  }
  .page6-wrap .inner6 .foot .f-top .logo span,
  .page6-wrap .inner6 .foot .f-top .about,
  .page6-wrap .inner6 .foot .f-bottom .fb-text{
    line-height: 1.5;
    font-size: 2vw;
  }
  .page6-wrap .inner6 .foot .f-bottom .f-img img{
    display: none;
  }
  .page6-wrap .inner6 .foot .f-middle{
    height: 10vw;
    overflow: auto;
    padding: 1vw 0vw;
    font-size: 2vw;
  }
  .page6-wrap .inner6 .foot .f-bottom .fb-text{
    margin-top: 2vw;
  }
  /deep/ .iScrollVerticalScrollbar{
    display: none;
  }
  
}

</style>
